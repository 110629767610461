





















import { Component, Prop, Vue} from 'vue-property-decorator';
import {RequiredFieldKey} from "@/entities/specs/spec-share-request-entity";
import { RequiredFields } from '@/entities/specs/spec-share-request-entity';

@Component({
  components: {
  }
})
export default class extends Vue {
  @Prop({required: true}) value!: RequiredFieldKey[];
  @Prop({default: false}) disabled!: boolean;

  private readonly RequiredFields = RequiredFields;

  private get model() { return this.value; }
  private set model(val) { this.$emit('input', val); }

  private isDisabled(key: RequiredFieldKey) {
    if (key === 'manufacturingProcessText') {
      return this.model.includes('manufacturingProcess@tableSimple');
    } else if (key === 'manufacturingProcess@tableSimple') {
      return this.model.includes('manufacturingProcessText');
    }
    return false;
  }
}
